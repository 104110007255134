<template>
  <a-modal v-model:visible="visible" title="模板信息" @ok="handleSubmit" @cancel="onCancel" :width="720">
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
      <a-form-item has-feedback label="模板名称" name="title">
        <a-input v-model:value="formState.title" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item has-feedback label="模板编号" name="code">
        <a-input v-model:value="formState.code" type="text" autocomplete="off" />
      </a-form-item>
      <a-form-item has-feedback label="模板封面" name="cover_image">
        <CsImage v-model:imgOne="formState.cover_image" />
      </a-form-item>
      <a-form-item has-feedback label="适用行业" name="tags">
        <a-input v-model:value="formState.tags" type="text" autocomplete="off" placeholder="选填、填写适用行业多个用|隔开" />
      </a-form-item>
      <a-form-item has-feedback label="模板简介" name="description">
        <a-textarea v-model:value="formState.description" placeholder="选填、填写模板摘要" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import CsImage from "@/components/UpImage";
import * as Api from "@/addons/client/api";
export default defineComponent({
  components: {
    CsImage,
  },
  name: "create-template",
  props: ["appid"],
  setup(props, context) {
    // 显示弹框
    const visible = ref(false);

    // 打开弹框
    const showFromModal = (data) => {
      console.log(data);
      if (data) {
        formState.tpl_id = data.tpl_id;
        formState.title = data.title;
        formState.code = data.code;
        formState.cover_image = data.cover_image;
        formState.tags = data.tags;
        formState.description = data.description;
      }
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      app_id: props.appid,
      title: "",
      code: "",
      cover_image: "",
      tags: "",
      description: "",
    });

    const rules = {
      title: [
        {
          required: true,
          min: 2,
          message: "请输入模板名称",
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          min: 2,
          message: "请输入至少模板编号",
          trigger: "change",
        },
      ],
      cover_image: [
        {
          required: true,
          message: "请上传模板封面",
          trigger: "change",
        },
      ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          Api.get_template_update(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              formRef.value.resetFields();
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmit,
    };
  },
});
</script>